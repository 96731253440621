import {
	Box,
	Button,
	CircularProgress,
	TextField,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import AlertComponent from '../components/AlertComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AltAccountCheck = () => {
	const [id, setId] = useState('');
	const [altAccount, setAltAccount] = useState(null);
	const [note, setNote] = useState('');
	const [originalBan, setOriginalBan] = useState(''); // State for original ban
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [copied, setCopied] = useState('');

	const handleSearch = async (e) => {
		e.preventDefault();
		setError('');
		setAltAccount(null);
		setLoading(true);

		try {
			const token = localStorage.getItem('authToken');
			const response = await axios.get(
				`${backendUrl}/api/alt-accounts/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setAltAccount(response.data);
			setNote(response.data.notes || '');
			setOriginalBan(response.data.original_ban || ''); // Set original ban from fetched data
		} catch (err) {
			setError('No alt accounts found for this ID');
		} finally {
			setLoading(false);
		}
	};

	const saveNote = async () => {
		try {
			const token = localStorage.getItem('authToken');
			console.log('Saving note for ID:', id); // Debugging log
			console.log('Note content:', note); // Debugging log

			const response = await axios.put(
				`${backendUrl}/api/alt-accounts/${id}/notes`,
				{ notes: note },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			alert('Note saved successfully');
			setAltAccount(response.data); // Update altAccount with the new data
		} catch (error) {
			console.error('Error saving note:', error); // More detailed error log
			setError('Failed to save note');
		}
	};

	const saveOriginalBan = async () => {
		try {
			const token = localStorage.getItem('authToken');
			const response = await axios.put(
				`${backendUrl}/api/alt-accounts/${id}/original-ban`,
				{ original_ban: originalBan },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			alert('Original ban saved successfully');
			setAltAccount(response.data);
		} catch (error) {
			console.error('Error saving original ban');
			setError('Failed to save original ban');
		}
	};

	const handleCopy = (idToCopy) => {
		navigator.clipboard.writeText(idToCopy).then(() => {
			setCopied(idToCopy);
			setTimeout(() => setCopied(''), 2000);
		});
	};

	return (
		<Box
			className='max-w-lg mx-auto p-6'
			style={{
				backgroundColor: '#1E293B',
				color: '#FFFFFF',
				borderRadius: '8px',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
			}}
		>
			<Typography variant='h6' className='mb-4'>
				Check for Alt Accounts
			</Typography>
			<form onSubmit={handleSearch} className='space-y-4'>
				<TextField
					variant='outlined'
					fullWidth
					label='Enter Steam ID or Player Unique Identifier ID'
					value={id}
					onChange={(e) => setId(e.target.value)}
					InputLabelProps={{ style: { color: '#D1D5DB' } }}
					InputProps={{ style: { color: '#FFFFFF' } }}
					style={{
						backgroundColor: '#0F172A',
						borderRadius: '8px',
						marginBottom: '16px',
					}}
				/>
				<Button
					type='submit'
					variant='contained'
					fullWidth
					disabled={loading}
					style={{
						backgroundColor: 'rgb(239, 68, 68)',
						color: '#FFFFFF',
					}}
				>
					{loading ? (
						<CircularProgress size={24} color='inherit' />
					) : (
						'Search'
					)}
				</Button>
			</form>

			{error && (
				<Typography
					variant='body1'
					style={{ color: '#EF4444', marginTop: '16px' }}
				>
					{error}
				</Typography>
			)}

			{altAccount && (
				<Box className='mt-4'>
					<Typography
						variant='subtitle1'
						style={{ color: '#B3B3B3' }}
					>
						Player Unique Identifier ID:{' '}
						{altAccount.player_unique_identifier_id}
					</Typography>

					<Typography variant='h6' style={{ marginBottom: '16px' }}>
						Alt Accounts for: {id}
					</Typography>
					<Box className='space-y-2'>
						{altAccount.steam_ids.map((steamId, index) => (
							<Box
								key={index}
								className='p-2 rounded-md cursor-pointer'
								onClick={() => handleCopy(steamId.id)} // Use steamId.id here
								title='Click to copy'
								style={{
									backgroundColor: '#0F172A',
									color: '#FFFFFF',
									borderColor: '#334155',
									border: '1px solid #334155',
									marginBottom: '8px',
								}}
							>
								{steamId.id} {/* Display steamId.id here */}
								{steamId.id === id && (
									<span
										style={{
											color: '#3B82F6',
											marginLeft: '8px',
										}}
									>
										(Searched ID)
									</span>
								)}
								{copied === steamId.id && (
									<span
										style={{
											color: '#10B981',
											marginLeft: '8px',
										}}
									>
										(Copied!)
									</span>
								)}
							</Box>
						))}
					</Box>
					<TextField
						label='Add Notes'
						variant='outlined'
						multiline
						fullWidth
						rows={4}
						value={note}
						onChange={(e) => setNote(e.target.value)}
						InputLabelProps={{ style: { color: '#D1D5DB' } }}
						InputProps={{ style: { color: '#FFFFFF' } }}
						style={{
							backgroundColor: '#0F172A',
							borderRadius: '8px',
							marginTop: '16px',
							marginBottom: '16px',
						}}
					/>
					<Button
						variant='contained'
						onClick={saveNote}
						fullWidth
						style={{
							backgroundColor: '#3B82F6',
							color: '#FFFFFF',
						}}
					>
						Save Note
					</Button>

					<TextField
						label='Original Ban'
						variant='outlined'
						multiline
						fullWidth
						rows={4}
						value={originalBan}
						onChange={(e) => setOriginalBan(e.target.value)}
						InputLabelProps={{ style: { color: '#D1D5DB' } }}
						InputProps={{ style: { color: '#FFFFFF' } }}
						style={{
							backgroundColor: '#0F172A',
							borderRadius: '8px',
							marginTop: '16px',
							marginBottom: '16px',
						}}
					/>
					<Button
						variant='contained'
						onClick={saveOriginalBan}
						fullWidth
						style={{
							backgroundColor: '#3B82F6',
							color: '#FFFFFF',
						}}
					>
						Save Original Ban
					</Button>
				</Box>
			)}
			<AlertComponent />
		</Box>
	);
};

export default AltAccountCheck;
