import { MenuItem, TextField } from '@mui/material';
import React from 'react';

const HourSelection = ({ selectedHour, setSelectedHour }) => (
	<TextField
		id='hour'
		select
		label='Select Hour'
		value={selectedHour}
		onChange={(e) => setSelectedHour(e.target.value)}
		InputLabelProps={{
			style: { color: '#D1D5DB' },
		}}
		InputProps={{
			style: { color: '#FFFFFF' },
		}}
		className='w-full'
		sx={{
			backgroundColor: '#0F172A',
			borderRadius: '8px',
			'& .MuiOutlinedInput-root': {
				'& fieldset': { borderColor: '#334155' },
				'&:hover fieldset': { borderColor: '#4B5563' },
				'&.Mui-focused fieldset': {
					borderColor: 'rgb(239, 68, 68)',
				},
			},
		}}
	>
		{['00', '03', '06', '09', '12', '15', '18', '21'].map((hour) => (
			<MenuItem key={hour} value={hour}>
				{`${hour}:00`}
			</MenuItem>
		))}
	</TextField>
);

export default HourSelection;
