import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

const GroupTerritoryLimitSearch = ({ server }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [results, setResults] = useState([]);
	const [error, setError] = useState('');

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleSearch = async () => {
		setIsLoading(true);
		setResults([]);
		setError('');

		try {
			const token = localStorage.getItem('authToken');

			const response = await axios.get(
				`${backendUrl}/api/${server}/combined-limit-check`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setResults(response.data);
		} catch (err) {
			console.error('Error fetching combined limit data:', err);
			setError('An error occurred while fetching the data.');
		} finally {
			setIsLoading(false);
		}
	};

	const renderMembersGrid = (members) => (
		<Grid container spacing={1}>
			{members.map((member, idx) => (
				<Grid item xs={6} md={3} key={idx}>
					<Card
						variant='outlined'
						style={{
							backgroundColor: '#1E293B',
							color: '#FFFFFF',
							textAlign: 'center',
							padding: '8px',
							borderRadius: '6px',
						}}
					>
						<CardContent style={{ padding: '8px' }}>
							<Typography
								variant='subtitle2'
								style={{ fontSize: '0.9rem' }}
							>
								{member.name}
							</Typography>
							<Typography
								variant='caption'
								style={{
									cursor: 'pointer',
									color: '#9CA3AF',
									fontSize: '0.75rem',
								}}
								onClick={() =>
									navigator.clipboard.writeText(
										member.steamid
									)
								}
							>
								Click to copy Steam ID
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);

	return (
		<Box
			className='limit-search-container'
			style={{
				backgroundColor: '#1E293B',
				padding: '16px',
				borderRadius: '8px',
			}}
		>
			<Typography
				variant='h5'
				className='mb-4'
				style={{ color: '#FFFFFF' }}
			>
				Combined Group and Territory Limit Check
			</Typography>

			<Box className='search-buttons mb-6'>
				<Button
					variant='contained'
					onClick={handleSearch}
					style={{
						backgroundColor: 'rgb(239, 68, 68)',
						color: '#FFFFFF',
					}}
				>
					Search Combined Limit
				</Button>
			</Box>

			{isLoading && (
				<Box className='loading-spinner'>
					<CircularProgress style={{ color: '#FFFFFF' }} />
					<Typography
						variant='body1'
						className='mt-2'
						style={{ color: '#FFFFFF' }}
					>
						Searching combined limits...
					</Typography>
				</Box>
			)}

			{error && <Typography color='error'>{error}</Typography>}

			{results.map((result, index) => (
				<Accordion
					key={index}
					className='mt-2 rounded-lg shadow-sm transition-shadow duration-300'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						border: '1px solid #334155',
						borderRadius: '8px',
						marginBottom: '12px',
					}}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon style={{ color: '#FFFFFF' }} />
						}
						style={{
							backgroundColor: '#1E293B',
							borderBottom: '1px solid #334155',
							padding: '12px',
						}}
					>
						<Typography
							style={{
								fontWeight: 'bold',
								color: '#FFFFFF',
							}}
						>
							{result.territoryGUID} - {result.groupName}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							backgroundColor: '#0F172A',
							color: '#FFFFFF',
						}}
					>
						<Typography
							variant='body2'
							style={{ marginBottom: '8px' }}
						>
							<strong>Group Members:</strong>
						</Typography>
						{renderMembersGrid(result.groupMembers)}

						<Typography
							variant='body2'
							style={{ margin: '16px 0 8px' }}
						>
							<strong>Territory Members:</strong>
						</Typography>
						{renderMembersGrid(result.territoryMembers)}

						{result.groupOnlyMembers.length > 0 && (
							<>
								<Typography
									variant='body2'
									style={{ margin: '16px 0 8px' }}
								>
									<strong>
										Members in Group but not in Territory:
									</strong>
								</Typography>
								{renderMembersGrid(result.groupOnlyMembers)}
							</>
						)}

						{result.territoryOnlyMembers.length > 0 && (
							<>
								<Typography
									variant='body2'
									style={{ margin: '16px 0 8px' }}
								>
									<strong>
										Members in Territory but not in Group:
									</strong>
								</Typography>
								{renderMembersGrid(result.territoryOnlyMembers)}
							</>
						)}
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};

export default GroupTerritoryLimitSearch;
