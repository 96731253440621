// ResultList.js
import { Box, Typography } from '@mui/material';
import React from 'react';

const ResultList = ({ filteredResults, noResultsMessage }) => (
	<Box className='mt-4'>
		{filteredResults.length > 0 ? (
			filteredResults.map((result, index) => (
				<Box
					key={index}
					className='p-2 mb-2 border rounded-md'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						borderColor: '#334155',
					}}
				>
					<Typography variant='body1' className='font-bold'>
						GUID: {result.guid || 'N/A'}
					</Typography>
					<Typography variant='body2'>
						Item: {result.item || 'N/A'}
					</Typography>
					<Typography variant='body2'>
						Count: {result.count || 'N/A'}
					</Typography>
					<Typography variant='body2' className='mt-1'>
						Positions:
						{result.positions && result.positions.length > 0 ? (
							result.positions.map((position, posIndex) => (
								<div key={posIndex}>{position}</div>
							))
						) : (
							<div>No positions available</div>
						)}
					</Typography>
				</Box>
			))
		) : (
			<Typography
				variant='body2'
				className='text-center mt-4'
				style={{ color: 'green' }}
			>
				{noResultsMessage}
			</Typography>
		)}
	</Box>
);

export default ResultList;
