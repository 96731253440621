// DateSelection.js
import { TextField } from '@mui/material';
import React from 'react';

const DateSelection = ({ selectedDate, setSelectedDate }) => (
	<TextField
		id='date'
		label='Select Date'
		type='date'
		value={selectedDate}
		onChange={(e) => setSelectedDate(e.target.value)}
		InputLabelProps={{
			shrink: true,
			style: { color: '#D1D5DB' },
		}}
		InputProps={{
			style: { color: '#FFFFFF' },
		}}
		className='w-full'
		sx={{
			backgroundColor: '#0F172A',
			color: '#FFFFFF',
			borderRadius: '8px',
			'& .MuiOutlinedInput-root': {
				'& fieldset': { borderColor: '#334155' },
				'&:hover fieldset': { borderColor: '#4B5563' },
				'&.Mui-focused fieldset': {
					borderColor: 'rgb(239, 68, 68)',
				},
			},
		}}
	/>
);

export default DateSelection;
