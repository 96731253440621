import axios from 'axios';
import React, { useState } from 'react';

const Register = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [role, setRole] = useState('admin'); // Default to admin, can be changed
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleRegister = async (e) => {
		e.preventDefault();
		setError(''); // Clear previous errors
		const token = localStorage.getItem('authToken'); // Retrieve the token

		try {
			const response = await axios.post(
				`${backendUrl}/api/auth/register`,
				{
					email,
					password,
					role,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`, // Include the token in the request
					},
				}
			);
			setSuccess('User registered successfully');
			console.log('Response:', response);
		} catch (err) {
			setError('Error during registration');
			console.error('Registration error:', err.response || err.message);
		}
	};

	return (
		<div
			className='min-h-screen flex items-center justify-center'
			style={{ backgroundColor: '#1E293B' }}
		>
			<div
				className='p-8 rounded-lg shadow-lg w-full max-w-md'
				style={{ backgroundColor: '#0F172A', color: '#FFFFFF' }}
			>
				<h2 className='text-3xl font-bold mb-6 text-center'>
					Register New User
				</h2>

				{error && <p className='text-red-500 mb-4'>{error}</p>}
				{success && <p className='text-green-500 mb-4'>{success}</p>}

				<form onSubmit={handleRegister} className='space-y-4'>
					<div className='relative'>
						<label className='block font-medium mb-1'>Email</label>
						<input
							type='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className='w-full p-3 rounded-lg focus:outline-none'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								border: '1px solid #334155',
							}}
							required
						/>
					</div>

					<div className='relative'>
						<label className='block font-medium mb-1'>
							Password
						</label>
						<input
							type='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className='w-full p-3 rounded-lg focus:outline-none'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								border: '1px solid #334155',
							}}
							required
						/>
					</div>

					<div className='relative'>
						<label className='block font-medium mb-1'>Role</label>
						<select
							value={role}
							onChange={(e) => setRole(e.target.value)}
							className='w-full p-3 rounded-lg focus:outline-none'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								border: '1px solid #334155',
							}}
						>
							<option value='admin'>Admin</option>
							<option value='sysadm'>SysAdm</option>
						</select>
					</div>

					<button
						type='submit'
						className='w-full py-3 rounded-lg font-medium'
						style={{
							backgroundColor: 'rgb(239, 68, 68)',
							color: '#FFFFFF',
							border: 'none',
						}}
					>
						Register
					</button>
				</form>
			</div>
		</div>
	);
};

export default Register;
