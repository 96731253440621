import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const GroupCheck = ({ server, preloadedGroup }) => {
	const [groupName, setGroupName] = useState(preloadedGroup || '');
	const [groupData, setGroupData] = useState(null);
	const [territories, setTerritories] = useState([]);
	const [error, setError] = useState('');
	const [selectedTerritory, setSelectedTerritory] = useState(null);
	const [loading, setLoading] = useState(false);

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	useEffect(() => {
		if (preloadedGroup) {
			handleSearch();
		}
	}, [preloadedGroup]);

	const handleSearch = async (e) => {
		if (e) e.preventDefault();
		setError('');
		setGroupData(null);
		setTerritories([]);
		setLoading(true);

		try {
			const token = localStorage.getItem('authToken');

			const response = await axios.get(
				`${backendUrl}/api/groups/${server}/group-check/${groupName}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setGroupData(response.data.group);
			setTerritories(response.data.territories);
		} catch (err) {
			setError('Group or player not found');
		} finally {
			setLoading(false);
		}
	};

	const handleCopySteamID = (steamID) => {
		navigator.clipboard.writeText(steamID).then(() => {
			alert(`Steam ID ${steamID} copied to clipboard!`);
		});
	};

	const handleTerritoryClick = (territory) => {
		setSelectedTerritory(territory);
	};

	const closeModal = () => {
		setSelectedTerritory(null);
	};

	const formatCoordinates = (coord) => Math.floor(coord);

	const getNonGroupMembers = (territoryMembers, groupMembers) => {
		const groupMemberUIDs = groupMembers.map((member) => member.steamid);
		return territoryMembers.filter(
			(member) => !groupMemberUIDs.includes(member.UID)
		);
	};

	return (
		<div
			className='p-6'
			style={{
				backgroundColor: '#1E293B', // Darker background color
				color: '#FFFFFF', // White text for readability
				borderRadius: '8px',
			}}
		>
			<h3
				className='text-lg font-semibold mb-4'
				style={{
					color: '#FFFFFF',
				}}
			>
				Search a group by entering the "Shortname" or full name
			</h3>
			<form className='flex gap-4 mb-6' onSubmit={handleSearch}>
				<input
					type='text'
					placeholder='Enter group name or SteamID'
					value={groupName}
					onChange={(e) => setGroupName(e.target.value)}
					className='border p-2 rounded-md w-64'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						borderColor: '#334155',
					}}
				/>
				<button
					type='submit'
					className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
				>
					Search
				</button>
			</form>
			{error && <p className='text-red-500'>{error}</p>}
			{loading && <p>Loading...</p>}
			{!loading && groupData && (
				<div className='space-y-8'>
					<div className='mb-8'>
						<h2 className='text-xl font-bold'>
							Group Name: {groupData.name}
						</h2>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>Members</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{groupData.members.map((member, index) => (
								<div
									key={index}
									className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
									onClick={() =>
										handleCopySteamID(member.steamid)
									}
									style={{
										backgroundColor: '#0F172A',
										color: '#FFFFFF',
										borderColor: '#334155',
									}}
								>
									<h4 className='text-md font-semibold'>
										{member.name}
									</h4>
									<p className='text-sm text-gray-400'>
										Click to copy Steam ID
									</p>
								</div>
							))}
						</div>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>Territories</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{territories.length > 0 ? (
								territories.map((territory, index) => (
									<div
										className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
										key={index}
										onClick={() =>
											handleTerritoryClick(territory)
										}
										style={{
											backgroundColor: '#0F172A',
											color: '#FFFFFF',
											borderColor: '#334155',
										}}
									>
										<h4 className='font-semibold'>
											Territory {index + 1}
										</h4>
									</div>
								))
							) : (
								<p>No territories found for this group.</p>
							)}
						</div>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>
							Players in Territory Not in Group
						</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{territories.length > 0 &&
								territories.map((territory, index) => {
									const nonGroupMembers = getNonGroupMembers(
										territory.Members,
										groupData.members
									);
									return nonGroupMembers.length > 0 ? (
										<div key={index}>
											<h4 className='font-semibold'>
												Territory {index + 1}
											</h4>
											<div className='grid grid-cols-1 gap-4 mt-2'>
												{nonGroupMembers.map(
													(member) => (
														<div
															key={member.UID}
															className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
															onClick={() =>
																handleCopySteamID(
																	member.UID
																)
															}
															style={{
																backgroundColor:
																	'#0F172A',
																color: '#FFFFFF',
																borderColor:
																	'#334155',
															}}
														>
															<h4 className='text-md font-semibold'>
																{member.Name}
															</h4>
															<p className='text-sm text-gray-400'>
																Click to copy
																Steam ID
															</p>
														</div>
													)
												)}
											</div>
										</div>
									) : (
										<p>
											No non-group members found in
											Territory {index + 1}.
										</p>
									);
								})}
						</div>
					</div>
				</div>
			)}
			{selectedTerritory && (
				<Modal
					isOpen={!!selectedTerritory}
					onRequestClose={closeModal}
					contentLabel='Territory Details'
					className='modal-content'
					overlayClassName='modal-overlay'
					style={{
						content: {
							backgroundColor: '#0F172A',
							color: '#FFFFFF',
							borderRadius: '8px',
							padding: '20px',
							maxWidth: '80%',
							margin: '0 auto', // Center the modal horizontally
							maxHeight: '90vh', // Limit height to prevent overflow
							overflowY: 'auto', // Enable scrolling if content is too long
						},
						overlay: {
							backgroundColor: 'rgba(0, 0, 0, 0.75)',
						},
					}}
				>
					<h2 className='text-xl font-bold mb-4'>
						Territory Details
					</h2>
					<p
						className='coords cursor-pointer'
						onClick={() =>
							navigator.clipboard.writeText(
								`${Math.floor(
									selectedTerritory.Pos_x
								)},${Math.floor(
									selectedTerritory.Pos_y
								)},${Math.floor(selectedTerritory.Pos_z)}`
							)
						}
						title='Click to copy coordinates'
					>
						<strong>Coordinates:</strong>{' '}
						{formatCoordinates(selectedTerritory.Pos_x)} x{' '}
						{formatCoordinates(selectedTerritory.Pos_z)}
					</p>
					<p>
						<strong>Territory UID:</strong> {selectedTerritory.GUID}
					</p>

					<h3 className='text-lg font-semibold mt-6'>Admins</h3>
					<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
						{selectedTerritory.AdminUIDS &&
						selectedTerritory.AdminUIDS.length > 0 ? (
							selectedTerritory.AdminUIDS.map(
								(adminUID, index) => {
									const admin =
										selectedTerritory.Members.find(
											(member) => member.UID === adminUID
										);
									return (
										<div
											key={index}
											className='p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
											style={{
												backgroundColor: '#0F172A',
												color: '#FFFFFF',
												borderColor: '#334155',
												border: '1px solid #334155',
											}}
											onClick={() =>
												navigator.clipboard.writeText(
													admin ? admin.UID : adminUID
												)
											}
										>
											<h4 className='font-semibold'>
												{admin ? admin.Name : 'Admin'}
											</h4>
											<p className='text-sm text-gray-400'>
												UID:{' '}
												{admin ? admin.UID : adminUID}
											</p>
											<p className='text-sm text-gray-400'>
												Click to copy Steam ID
											</p>
										</div>
									);
								}
							)
						) : (
							<p>No Admins Found</p>
						)}
					</div>

					<h3 className='text-lg font-semibold mt-6'>Members</h3>
					<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
						{selectedTerritory.Members.map((member, index) => (
							<div
								key={index}
								className='p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
								style={{
									backgroundColor: '#0F172A',
									color: '#FFFFFF',
									borderColor: '#334155',
									border: '1px solid #334155',
								}}
								onClick={() =>
									navigator.clipboard.writeText(member.UID)
								}
							>
								<h4 className='font-semibold'>{member.Name}</h4>
								<p className='text-sm text-gray-400'>
									UID: {member.UID}
								</p>
								<p className='text-sm text-gray-400'>
									Click to copy Steam ID
								</p>
							</div>
						))}
					</div>

					<button
						onClick={closeModal}
						className='mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600'
					>
						Close
					</button>
				</Modal>
			)}
		</div>
	);
};

export default GroupCheck;
