import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AltAccountCheck from '../components/AltAccountCheck';
import ButtonMenu from '../components/ButtonMenu';
import DupedItemsCheck from '../components/DupeCheck/DupedItemsCheck';
import GroupCheck from '../components/GroupCheck';
import GroupTerritoryLimitSearch from '../components/GroupTerritoryLimitSearch';
import TerritoryCheck from '../components/TerritoryCheck/TerritoryCheck';
import TerritoryNotPaid from '../components/TerritoryNotPaid';
import UserSearch from '../components/UserSearch';

const ServerPage = () => {
	const { server } = useParams();
	const location = useLocation();

	const serverMap = {
		chernarus1: 'cherno1',
		chernarus2: 'cherno2',
		chernarus3: 'cherno3',
		deerisle: 'deerisle',
		livonia1: 'livonia1',
		sakhaleu1: 'sakhaleu1',
		sakhal2: 'sakhal2',
		pripyat: 'pripyat',
	};

	const backendServerName = serverMap[server];
	const [selectedComponent, setSelectedComponent] = useState('UserSearch');
	const [preloadedGroup, setPreloadedGroup] = useState('');
	const [preloadedPlayer, setPreloadedPlayer] = useState('');

	useEffect(() => {
		if (location.state) {
			const { steamID, group } = location.state;
			setPreloadedPlayer(steamID);
			setPreloadedGroup(group);
			setSelectedComponent('UserSearch');
		}
	}, [location.state]);

	if (!backendServerName) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				// height='100%'
				sx={{ backgroundColor: '#1E293B' }}
			>
				<Typography variant='h4' color='error'>
					Server not found
				</Typography>
			</Box>
		);
	}

	const renderComponent = () => {
		switch (selectedComponent) {
			case 'UserSearch':
				return (
					<UserSearch
						server={backendServerName}
						onGroupClick={handleGroupClick}
						preloadedPlayer={preloadedPlayer}
					/>
				);
			case 'GroupCheck':
				return (
					<GroupCheck
						server={backendServerName}
						preloadedGroup={preloadedGroup}
					/>
				);
			case 'TerritoryCheck':
				return <TerritoryCheck server={backendServerName} />;
			case 'AltAccountCheck':
				return <AltAccountCheck />;
			case 'GroupTerritoryLimitSearch':
				return <GroupTerritoryLimitSearch server={backendServerName} />;
			case 'DupedItemsCheck':
				return <DupedItemsCheck selectedServer={backendServerName} />;
			case 'TerritoryNotPaid':
				return <TerritoryNotPaid server={backendServerName} />;
			default:
				return null;
		}
	};

	const handleGroupClick = (groupName) => {
		setPreloadedGroup(groupName);
		setSelectedComponent('GroupCheck');
	};

	return (
		<Box className='serverPage' display='flex' p={2}>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Paper
						elevation={3}
						className='p-6'
						sx={{
							backgroundColor: '#0F172A',
							color: '#ffffff',
						}}
					>
						<Typography
							variant='h4'
							className='mb-4'
							fontWeight='bold'
						>
							{server.toUpperCase()}
						</Typography>
						{renderComponent()}
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper
						elevation={3}
						className='p-4'
						sx={{
							backgroundColor: '#0F172A',
							color: '#ffffff',
						}}
					>
						<ButtonMenu
							setSelectedComponent={setSelectedComponent}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ServerPage;
