// FilterModal.js
import SearchIcon from '@mui/icons-material/Search';
import {
	Box,
	Button,
	Grid,
	IconButton,
	InputBase,
	Modal,
	Typography,
} from '@mui/material';
import React from 'react';

const FilterModal = ({
	filterModalOpen,
	setFilterModalOpen,
	filteredItems,
	handleFilterSelection,
	searchQuery,
	setSearchQuery,
}) => (
	<Modal open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
		<Box
			className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 rounded-lg shadow-md'
			style={{
				width: '95%',
				maxWidth: '600px',
				maxHeight: '70vh',
				overflowY: 'auto',
				backgroundColor: '#0F172A',
				color: '#FFFFFF',
			}}
		>
			<Typography variant='h6' className='mb-4 text-center'>
				Select an Item to Filter
			</Typography>
			<Box className='mb-4 flex items-center'>
				<SearchIcon />
				<InputBase
					placeholder='Search items...'
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					className='ml-2 flex-grow'
					style={{
						backgroundColor: '#1E293B',
						color: '#FFFFFF',
						padding: '8px',
						borderRadius: '4px',
						width: '100%',
					}}
				/>
			</Box>
			<Grid container spacing={2}>
				{filteredItems.map((item, index) => (
					<Grid item xs={6} key={index}>
						<Button
							variant='outlined'
							style={{
								width: '100%',
								backgroundColor: '#0F172A',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
							onClick={() => handleFilterSelection(item)}
						>
							{item}
						</Button>
					</Grid>
				))}
			</Grid>
			<Button
				variant='contained'
				color='secondary'
				className='w-full mt-4'
				onClick={() => handleFilterSelection('')}
				sx={{
					backgroundColor: 'rgb(239, 68, 68)',
					'&:hover': { backgroundColor: 'rgb(220, 38, 38)' },
				}}
			>
				Clear Filter
			</Button>
		</Box>
	</Modal>
);

export default FilterModal;
