import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginComponent = ({ setAuthToken }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleLogin = async (e) => {
		e.preventDefault();
		setError(''); // Clear previous errors

		try {
			const response = await axios.post(`${backendUrl}/api/auth/login`, {
				email,
				password,
			});
			console.log('Response:', response);

			// Check if token is present in the response
			if (response.data.token) {
				localStorage.setItem('authToken', response.data.token); // Save token
				setAuthToken(response.data.token); // Call the setter function if necessary
				console.log(
					'Login successful, token set:',
					response.data.token
				);
				setError(''); // Clear the error if login is successful
				navigate('/'); // Redirect to homepage after successful login
			} else {
				setError('Login failed. No token received.');
			}
		} catch (err) {
			console.log('Error during login:', err.response || err.message);
			setError('Invalid email or password');
		}
	};

	return (
		<div
			className='min-h-screen flex items-center justify-center'
			style={{ backgroundColor: '#1E293B' }} // Use new background color
		>
			<div
				className='rounded-lg shadow-lg p-8 max-w-md w-full space-y-6'
				style={{
					backgroundColor: '#0F172A',
					color: '#FFFFFF',
				}}
			>
				<h2 className='text-3xl font-bold text-center'>Login</h2>

				{error && (
					<p
						className='p-3 rounded-md text-center'
						style={{
							backgroundColor: '#F87171',
							color: '#FFFFFF',
						}}
					>
						{error}
					</p>
				)}

				<form onSubmit={handleLogin} className='space-y-4'>
					<div className='relative'>
						<label
							htmlFor='email'
							className='block font-medium mb-1'
						>
							Email
						</label>
						<input
							type='email'
							id='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className='w-full p-3 rounded-lg focus:outline-none'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								border: '1px solid #334155',
							}}
							placeholder='you@example.com'
							required
						/>
					</div>

					<div className='relative'>
						<label
							htmlFor='password'
							className='block font-medium mb-1'
						>
							Password
						</label>
						<input
							type='password'
							id='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className='w-full p-3 rounded-lg focus:outline-none'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								border: '1px solid #334155',
							}}
							placeholder='••••••••'
							required
						/>
					</div>

					<button
						type='submit'
						className='w-full py-3 rounded-lg font-medium shadow-lg transform transition-all'
						style={{
							backgroundColor: 'rgb(239, 68, 68)',
							color: '#FFFFFF',
							border: 'none',
						}}
					>
						Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default LoginComponent;
