import {
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import {
	FaDownload,
	FaSignOutAlt,
	FaSyncAlt,
	FaUserPlus,
} from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import '../assets/scss/menubar.scss';

const MenuBar = ({ userRole }) => {
	const menuItems = [
		{ name: 'Cherno 1', to: '/chernarus1' },
		{ name: 'Cherno 2', to: '/chernarus2' },
		{ name: 'Cherno 3', to: '/chernarus3' },
		{ name: 'Deer Isle', to: '/deerisle' },
		{ name: 'Livonia', to: '/livonia1' },
		{ name: 'Sakhal 1', to: '/sakhaleu1' },
		{ name: 'Sakhal 2', to: '/sakhal2' },
		{ name: 'Pripyat', to: '/pripyat' },
	];

	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const navigate = useNavigate();

	// const handleFTPDownload = async () => {
	// 	try {
	// 		const token = localStorage.getItem('authToken');
	// 		await axios.post(
	// 			`${backendUrl}/api/run-ftp-downloads`,
	// 			{},
	// 			{
	// 				headers: { Authorization: `Bearer ${token}` },
	// 			}
	// 		);
	// 		alert('FTP downloads started successfully!');
	// 	} catch (error) {
	// 		console.error('Error triggering FTP download:', error);
	// 		alert('Failed to start FTP downloads.');
	// 	}
	// };

	// const handleManualUpdate = async () => {
	// 	try {
	// 		const token = localStorage.getItem('authToken');
	// 		await axios.post(
	// 			`${backendUrl}/api/alt-accounts/update`,
	// 			{},
	// 			{
	// 				headers: { Authorization: `Bearer ${token}` },
	// 			}
	// 		);
	// 		alert('Alt accounts updated successfully!');
	// 	} catch (error) {
	// 		console.error('Error updating alt accounts:', error);
	// 		alert('Failed to update alt accounts.');
	// 	}
	// };

	const logout = async () => {
		localStorage.removeItem('authToken');
		navigate('/login');
	};

	return (
		<div
			className='menu-bar flex flex-col h-screen p-4'
			style={{ backgroundColor: '#0F172A' }}
		>
			<NavLink
				to='/user-search-all-servers'
				className={({ isActive }) =>
					`w-full py-2 px-4 rounded-lg no-underline transition-colors ${
						isActive ? 'bg-green-500' : 'hover:bg-gray-700'
					}`
				}
				style={{ color: '#FFFFFF', marginBottom: '20px' }}
			>
				<Typography variant='h6' className='font-bold'>
					Search players across servers
				</Typography>
			</NavLink>

			<Typography
				variant='h6'
				className='font-bold'
				style={{
					color: '#FFFFFF',
					marginBottom: '4px',
					marginTop: '20px',
				}}
			>
				Game Servers
			</Typography>
			<List style={{ marginBottom: '20px' }}>
				{menuItems.map((item, index) => (
					<ListItem key={index} disablePadding>
						<NavLink
							to={item.to}
							className={({ isActive }) =>
								`w-full py-2 px-4 rounded-lg no-underline transition-colors ${
									isActive
										? 'bg-green-500'
										: 'hover:bg-gray-700'
								}`
							}
							style={{ color: '#FFFFFF' }}
						>
							<ListItemText primary={item.name} />
						</NavLink>
					</ListItem>
				))}
			</List>

			{/* New Alt Account Search Link */}
			<Typography
				variant='h6'
				className='font-bold'
				style={{
					color: '#FFFFFF',
					marginBottom: '10px',
					marginTop: '20px',
				}}
			>
				Alt Account Search
			</Typography>
			<List style={{ marginBottom: '20px' }}>
				<ListItem disablePadding>
					<NavLink
						to='/alt-account-check' // Path for Alt Account Check page
						className={({ isActive }) =>
							`w-full py-2 px-4 rounded-lg no-underline transition-colors ${
								isActive ? 'bg-green-500' : 'hover:bg-gray-700'
							}`
						}
						style={{ color: '#FFFFFF' }}
					>
						<ListItemText primary='Search Alt Accounts' />
					</NavLink>
				</ListItem>
			</List>

			<Typography
				variant='h6'
				className='font-bold'
				style={{
					color: '#FFFFFF',
					marginBottom: '10px',
					marginTop: '20px',
				}}
			>
				Flea Market
			</Typography>
			<List style={{ marginBottom: '20px' }}>
				<ListItem disablePadding>
					<NavLink
						to='/flea-market'
						className={({ isActive }) =>
							`w-full py-2 px-4 rounded-lg no-underline transition-colors ${
								isActive ? 'bg-green-500' : 'hover:bg-gray-700'
							}`
						}
						style={{ color: '#FFFFFF' }}
					>
						<ListItemText primary='Search player listings' />
					</NavLink>
				</ListItem>
			</List>

			<div className='mt-auto flex justify-center space-x-4'>
				<IconButton
					onClick={logout}
					style={{
						backgroundColor: 'rgb(239, 68, 68)',
						color: '#FFFFFF',
					}}
				>
					<FaSignOutAlt />
				</IconButton>

				{userRole === 'sysadm' && (
					<>
						<ListItem disablePadding>
							<NavLink
								to='/administrate'
								className={({ isActive }) =>
									`w-full py-2 px-4 rounded-lg no-underline transition-colors ${
										isActive
											? 'bg-green-500'
											: 'hover:bg-gray-700'
									}`
								}
								style={{ color: '#FFFFFF' }}
							>
								<ListItemText primary='Administrate' />
							</NavLink>
						</ListItem>
					</>
				)}
			</div>
		</div>
	);
};

export default MenuBar;
