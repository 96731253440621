import axios from 'axios';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const NearbyLoginsCheck = ({
	server,
	territoryPosX,
	territoryPosZ,
	excludePlayers,
}) => {
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [copied, setCopied] = useState('');

	const toggleAccordion = () => setIsOpen(!isOpen);

	const handleCopyCoordinates = (posX, posY, posZ) => {
		const coordinates = `${Math.floor(posX)},${Math.floor(
			posY
		)},${Math.floor(posZ)}`;
		navigator.clipboard.writeText(coordinates).then(() => {
			setCopied(coordinates);
			setTimeout(() => setCopied(''), 2000);
		});
	};

	const handleSearch = async () => {
		setLoading(true);
		setError('');
		try {
			const token = localStorage.getItem('authToken');
			const dateQuery = selectedDate
				? `?date=${selectedDate.getFullYear()}-${String(
						selectedDate.getMonth() + 1
				  ).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(
						2,
						'0'
				  )}`
				: '';

			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/${server}/nearby-logins/${territoryPosX}/${territoryPosZ}${dateQuery}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (response.status === 200 && response.data.events) {
				const filteredEvents = response.data.events.filter(
					(event) => !excludePlayers.includes(event.steamID)
				);
				setEvents(filteredEvents);
			} else {
				setEvents([]);
			}
		} catch (err) {
			console.error('Error with nearby logins request:', err); // Log more details on the error
			setError('An error occurred while fetching nearby logins/logouts');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='my-4'>
			<h2
				onClick={toggleAccordion}
				className='cursor-pointer p-4 rounded'
				style={{
					backgroundColor: '#2C3748', // Lighter header background
					color: '#FFFFFF',
					transition: 'background-color 0.3s',
				}}
				onMouseEnter={(e) =>
					(e.target.style.backgroundColor = '#374151')
				}
				onMouseLeave={(e) =>
					(e.target.style.backgroundColor = '#2C3748')
				}
			>
				Nearby Logins/Logouts (Click to {isOpen ? 'Close' : 'Open'})
			</h2>

			{isOpen && (
				<div
					className='accordion-content p-4'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						borderRadius: '8px',
					}}
				>
					<div className='flex space-x-4 mb-4'>
						<DatePicker
							selected={selectedDate}
							onChange={setSelectedDate}
							dateFormat='yyyy-MM-dd'
							className='border px-4 py-2 rounded w-full'
							placeholderText='Select day to search'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
							popperClassName='custom-datepicker'
							customInput={
								<input
									style={{
										backgroundColor: '#1E293B',
										color: '#FFFFFF',
										borderColor: '#334155',
										padding: '10px',
										borderRadius: '4px',
										width: '100%',
									}}
								/>
							}
						/>
						<button
							className={`py-2 px-4 rounded ${
								selectedDate
									? 'bg-blue-500 text-white'
									: 'bg-gray-300 text-gray-600'
							} transition-colors duration-300`}
							onClick={handleSearch}
							disabled={!selectedDate}
						>
							Search
						</button>
					</div>
					{loading && <p>Loading...</p>}
					{error && <p className='text-red-500'>{error}</p>}
					{events.length > 0 ? (
						<table className='min-w-full bg-gray-800 rounded-lg'>
							<thead>
								<tr style={{ backgroundColor: '#1E293B' }}>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Timestamp
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Name (SteamID)
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Event
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Coordinates
									</th>
								</tr>
							</thead>
							<tbody>
								{events.map((event, index) => (
									<tr
										key={index}
										className='hover:bg-gray-700 transition-all'
									>
										<td className='py-2 px-4 border-b border-gray-700'>
											{event.timestamp}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{event.playerName} ({event.steamID})
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{event.type === 'login'
												? 'Logged In'
												: 'Logged Out'}
										</td>
										<td
											className='py-2 px-4 border-b border-gray-700 cursor-pointer text-blue-500'
											onClick={() =>
												handleCopyCoordinates(
													event.coordinates?.posX ||
														0,
													event.coordinates?.posY ||
														0,
													event.coordinates?.posZ || 0
												)
											}
										>
											{event.coordinates?.posX ?? ''},
											{event.coordinates?.posY ?? ''},
											{event.coordinates?.posZ ?? ''}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<p className='text-gray-500'>
							No login or logout events found.
						</p>
					)}
					{copied && (
						<p className='text-green-500 mt-2'>Copied: {copied}</p>
					)}
				</div>
			)}
		</div>
	);
};

export default NearbyLoginsCheck;
