// DupedItemsCheck.js
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import DateSelection from './DateSelection';
import FilterModal from './FilterModal';
import GUIDSearch from './GUIDSearch';
import HourSelection from './HourSelection';
import ResultList from './ResultList';

const DupedItemsCheck = ({ selectedServer }) => {
	const [selectedDate, setSelectedDate] = useState('');
	const [selectedHour, setSelectedHour] = useState('');
	const [results, setResults] = useState([]);
	const [error, setError] = useState('');
	const [guid, setGuid] = useState('');
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [noResultsMessage, setNoResultsMessage] = useState('');

	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const RESULTS_PER_PAGE = 20;

	// Define handleFilterSelection within this component
	const handleFilterSelection = (item) => {
		setSelectedItem(item);
		setFilterModalOpen(false); // Close the filter modal
		setCurrentPage(1); // Reset pagination when a filter is applied
	};

	const handleSubmit = async () => {
		setError('');
		setNoResultsMessage(''); // Clear any previous no results message

		const token = localStorage.getItem('authToken');
		if (!token) {
			setError('Authorization token is missing. Please log in again.');
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/dupe-check`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						server: selectedServer,
						date: selectedDate,
						hour: selectedHour,
					}),
				}
			);

			const data = await response.json();
			console.log('Dupe Check Results:', data);

			if (data && data.length > 0) {
				// Sort the results by 'count' in descending order before setting it to state
				const sortedData = data.sort(
					(a, b) => (b.count || 0) - (a.count || 0)
				);
				setResults(sortedData); // Populate sorted results
			} else {
				setResults([]); // Clear results to ensure empty array
				setNoResultsMessage('No duped items found'); // Set no results message
			}
		} catch (err) {
			setError(`Failed to fetch results: ${err.message}`);
		}
	};

	const handleGuidSearch = async () => {
		setError('');

		const token = localStorage.getItem('authToken');
		if (!token) {
			setError('Authorization token is missing. Please log in again.');
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/search-guid`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ server: selectedServer, guid }),
				}
			);

			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			let allResults = [];

			setResults([]); // Clear previous results

			while (true) {
				const { done, value } = await reader.read();
				if (done) break;

				const chunk = decoder.decode(value);
				let parsedChunk;

				try {
					parsedChunk = JSON.parse(chunk);
				} catch (e) {
					console.error('Failed to parse chunk:', chunk);
					continue;
				}

				// Ensure parsedChunk contains the expected data
				if (parsedChunk.batchResults) {
					allResults = [...allResults, ...parsedChunk.batchResults];
					setResults(allResults);
				}
			}
		} catch (err) {
			setError(`Failed to fetch results: ${err.message}`);
		}
	};

	const uniqueItems = [
		...new Set(results.map((result) => (result.item ? result.item : ''))),
	];

	const filteredItems = uniqueItems.filter((item) =>
		item.toLowerCase().includes(searchQuery.toLowerCase())
	);
	const filteredResults = selectedItem
		? results.filter((result) => result.item === selectedItem)
		: results;

	const paginatedResults = filteredResults.slice(
		(currentPage - 1) * RESULTS_PER_PAGE,
		currentPage * RESULTS_PER_PAGE
	);

	const handleNextPage = () => {
		if (currentPage * RESULTS_PER_PAGE < filteredResults.length) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<Box
			className='w-full max-w-xl mx-auto p-4'
			style={{
				backgroundColor: '#1E293B',
				color: '#FFFFFF',
				borderRadius: '8px',
			}}
		>
			<Typography
				variant='h5'
				className='mb-2 text-center font-bold'
				style={{ color: '#FFFFFF' }}
			>
				Checking Duped Items in {selectedServer}
			</Typography>

			{/* Date and Hour Selection */}
			<DateSelection
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
			/>
			<HourSelection
				selectedHour={selectedHour}
				setSelectedHour={setSelectedHour}
			/>

			<Button
				variant='contained'
				className='mt-4'
				sx={{
					backgroundColor: 'rgb(239, 68, 68)',
					'&:hover': { backgroundColor: 'rgb(220, 38, 38)' },
					width: '100%',
				}}
				onClick={handleSubmit}
			>
				Check Dupes
			</Button>

			{/* Filter button and modal */}
			{results.length > 0 && (
				<Box className='flex justify-end mt-4 space-x-2'>
					<Button
						variant='outlined'
						startIcon={<FilterListIcon />}
						onClick={() => setFilterModalOpen(true)}
						sx={{
							color: '#FFFFFF',
							borderColor: '#334155',
							'&:hover': { backgroundColor: '#0F172A' },
						}}
					>
						Filter
					</Button>
					{selectedItem && (
						<Button
							variant='outlined'
							color='secondary'
							onClick={() => setSelectedItem('')}
							sx={{
								color: '#FFFFFF',
								borderColor: '#334155',
								'&:hover': { backgroundColor: '#0F172A' },
							}}
						>
							Clear Filter
						</Button>
					)}
				</Box>
			)}
			<FilterModal
				filterModalOpen={filterModalOpen}
				setFilterModalOpen={setFilterModalOpen}
				filteredItems={filteredItems}
				handleFilterSelection={handleFilterSelection} // Pass the function here
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
			/>

			{/* Display results */}
			<ResultList
				filteredResults={paginatedResults}
				noResultsMessage={noResultsMessage}
			/>

			{/* Pagination Controls */}
			{filteredResults.length > RESULTS_PER_PAGE && (
				<Box className='flex justify-between mt-4'>
					<Button
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
					>
						Previous
					</Button>
					<Typography>{`Page ${currentPage} of ${Math.ceil(
						filteredResults.length / RESULTS_PER_PAGE
					)}`}</Typography>
					<Button
						onClick={handleNextPage}
						disabled={
							currentPage * RESULTS_PER_PAGE >=
							filteredResults.length
						}
					>
						Next
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default DupedItemsCheck;
