// src/pages/UserSearchAllServers.js

import {
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UserSearchAllServers = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const serverMap = {
		cherno1: 'chernarus1',
		cherno2: 'chernarus2',
		cherno3: 'chernarus3',
		deerisle: 'deerisle',
		livonia1: 'livonia1',
		sakhal1: 'sakhaleu1',
		sakhal2: 'sakhal2',
		pripyat: 'pripyat',
	};

	useEffect(() => {
		if (searchTerm) {
			setLoading(true);
			const token = localStorage.getItem('authToken');

			axios
				.get(`${backendUrl}/api/users/search-all`, {
					params: { searchTerm },
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					setResults(response.data.results);
					setLoading(false);
				})
				.catch((error) => {
					console.error('Error fetching search results:', error);
					setLoading(false);
				});
		} else {
			setResults([]);
		}
	}, [searchTerm]);

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleGroupClick = (serverShortName, group, playerName, steamID) => {
		const serverUrl = serverMap[serverShortName];
		if (serverUrl) {
			navigate(`/${serverUrl}`, { state: { steamID, group } });
		} else {
			console.error('Invalid server name:', serverShortName);
		}
	};

	return (
		<Paper
			elevation={3}
			sx={{
				backgroundColor: '#0F172A',
				color: '#ffffff',
				padding: '16px',
				borderRadius: '8px',
			}}
		>
			<Typography variant='h5' gutterBottom sx={{ color: '#ffffff' }}>
				Search Across All Servers
			</Typography>
			<TextField
				label='Search for a player'
				variant='outlined'
				fullWidth
				value={searchTerm}
				onChange={handleSearchChange}
				sx={{
					backgroundColor: '#1E293B',
					input: { color: '#ffffff' },
					label: { color: '#9CA3AF' },
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: '#4B5563',
						},
						'&:hover fieldset': {
							borderColor: '#6B7280',
						},
						'&.Mui-focused fieldset': {
							borderColor: '#ffffff',
						},
					},
					marginBottom: '16px',
				}}
			/>
			{loading ? (
				<CircularProgress sx={{ color: '#ffffff' }} />
			) : (
				<List>
					{results.length > 0 ? (
						results.map((result, index) => (
							<ListItem
								key={index}
								onClick={() =>
									handleGroupClick(
										result.server,
										result.user.group,
										result.user.name,
										result.user.steamid
									)
								}
								button
								sx={{
									backgroundColor: '#1E293B',
									borderRadius: '8px',
									marginBottom: '8px',
									'&:hover': {
										backgroundColor: '#334155',
									},
								}}
							>
								<ListItemText
									primary={`Player: ${result.user.name}`}
									secondary={`Server: ${result.server}, Group: ${result.user.group}`}
									primaryTypographyProps={{
										color: '#ffffff',
									}}
									secondaryTypographyProps={{
										color: '#9CA3AF',
									}}
								/>
							</ListItem>
						))
					) : (
						<Typography variant='body2' sx={{ color: '#9CA3AF' }}>
							No results found
						</Typography>
					)}
				</List>
			)}
		</Paper>
	);
};

export default UserSearchAllServers;
