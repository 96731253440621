import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ItemsTaken = ({ server, territoryPosX, territoryPosZ }) => {
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	const handleSearch = async () => {
		setLoading(true);
		setError('');

		try {
			const token = localStorage.getItem('authToken');

			const dateQuery = selectedDate
				? `?date=${selectedDate.getFullYear()}-${String(
						selectedDate.getMonth() + 1
				  ).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(
						2,
						'0'
				  )}`
				: '';

			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/${server}/items-taken/${territoryPosX}/${territoryPosZ}${dateQuery}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (response.status === 200 && response.data.items) {
				const sortedItems = response.data.items.sort((a, b) => {
					const dateA = new Date(
						`1970-01-01T${a.timestamp.split(' | ')[1]}:00Z`
					);
					const dateB = new Date(
						`1970-01-01T${b.timestamp.split(' | ')[1]}:00Z`
					);
					return dateB - dateA;
				});
				setItems(sortedItems);
			} else {
				setItems([]);
			}
		} catch (err) {
			setError('An error occurred fetching items taken from base');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='my-4'>
			<h2
				onClick={toggleAccordion}
				className='cursor-pointer p-4 rounded'
				style={{
					backgroundColor: '#2C3748', // Lighter header background
					color: '#FFFFFF',
					transition: 'background-color 0.3s',
				}}
				onMouseEnter={(e) =>
					(e.target.style.backgroundColor = '#374151')
				}
				onMouseLeave={(e) =>
					(e.target.style.backgroundColor = '#2C3748')
				}
			>
				Items Taken from Base (Click to {isOpen ? 'Close' : 'Open'})
			</h2>

			{isOpen && (
				<div
					className='accordion-content p-4'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						borderRadius: '8px',
					}}
				>
					<div className='flex space-x-4 mb-4'>
						<DatePicker
							selected={selectedDate}
							onChange={setSelectedDate}
							dateFormat='yyyy-MM-dd'
							className='border px-4 py-2 rounded w-full'
							placeholderText='Select day to search'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
							popperClassName='custom-datepicker'
							customInput={
								<input
									style={{
										backgroundColor: '#1E293B',
										color: '#FFFFFF',
										borderColor: '#334155',
										padding: '10px',
										borderRadius: '4px',
										width: '100%',
									}}
								/>
							}
						/>
						<button
							className={`py-2 px-4 rounded ${
								selectedDate
									? 'bg-blue-500 text-white'
									: 'bg-gray-300 text-gray-600'
							} transition-colors duration-300`}
							onClick={handleSearch}
							disabled={!selectedDate}
						>
							Search
						</button>
					</div>
					{loading && <p>Loading...</p>}
					{error && <p className='text-red-500'>{error}</p>}
					{items.length > 0 ? (
						<table className='min-w-full bg-gray-800 rounded-lg'>
							<thead>
								<tr style={{ backgroundColor: '#1E293B' }}>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Timestamp
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Name (SteamID)
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Item
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										From
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Coordinates
									</th>
								</tr>
							</thead>
							<tbody>
								{items.map((item, index) => (
									<tr
										key={index}
										className='hover:bg-gray-700 transition-all'
									>
										<td className='py-2 px-4 border-b border-gray-700'>
											{item.timestamp}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{item.playerName} ({item.steamID})
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{item.itemName}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{item.from}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{item.coordinates.posX},{' '}
											{item.coordinates.posZ}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<p className='text-gray-500'>
							No items taken from base found.
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default ItemsTaken;
