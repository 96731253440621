import axios from 'axios';
import React, { useState } from 'react';
import ItemsTaken from './ItemsTaken/ItemsTaken';
import PlayersNear from './PlayersNear/PlayersNear';
import DupedItemsTerritoryCheck from './TerritoryDupeCheck/DupedItemsTerritoryCheck';
import NearbyLoginsCheck from './loginout/NearbyLoginCheck';
import TerritoryEvents from './territoryLogs/TerritoryEvents';

const TerritoryCheck = ({ server }) => {
	const [posX, setPosX] = useState('');
	const [posZ, setPosZ] = useState('');
	const [steamID, setSteamID] = useState('');
	const [results, setResults] = useState(null);
	const [error, setError] = useState('');
	const [copied, setCopied] = useState('');
	const [searchBySteamID, setSearchBySteamID] = useState(false);

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleSearch = async (e) => {
		e.preventDefault();
		setError('');
		setResults(null);

		try {
			const token = localStorage.getItem('authToken');
			const searchUrl = searchBySteamID
				? `${backendUrl}/api/${server}/territory-search-by-steamid/${steamID}`
				: `${backendUrl}/api/${server}/territory-search/${posX}/${posZ}`;

			const response = await axios.get(searchUrl, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			setResults(response.data);
		} catch (err) {
			setError('Territory not found');
		}
	};

	const handleCopyCoordinates = (posX, posY, posZ) => {
		const coordinates = `${Math.floor(posX)},${Math.floor(
			posY
		)},${Math.floor(posZ)}`;
		navigator.clipboard.writeText(coordinates).then(() => {
			setCopied(coordinates);
			setTimeout(() => setCopied(''), 2000);
		});
	};

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			setCopied(text);
			setTimeout(() => setCopied(''), 2000);
		});
	};

	return (
		<div
			className='max-w-xxl mx-auto p-4'
			style={{
				backgroundColor: '#1E293B',
				color: '#FFFFFF',
				borderRadius: '8px',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
			}}
		>
			<form onSubmit={handleSearch} className='mb-6 space-y-4'>
				<label style={{ color: '#D1D5DB' }}>
					<input
						type='checkbox'
						checked={searchBySteamID}
						onChange={(e) => setSearchBySteamID(e.target.checked)}
					/>{' '}
					Search by SteamID
				</label>

				{!searchBySteamID ? (
					<div className='flex items-center space-x-4'>
						<input
							type='number'
							placeholder='Enter X axis'
							value={posX}
							onChange={(e) => setPosX(e.target.value)}
							className='w-full px-4 py-2 rounded-md'
							style={{
								backgroundColor: '#0F172A',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
						/>
						<input
							type='number'
							placeholder='Enter Z axis'
							value={posZ}
							onChange={(e) => setPosZ(e.target.value)}
							className='w-full px-4 py-2 rounded-md'
							style={{
								backgroundColor: '#0F172A',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
						/>
					</div>
				) : (
					<input
						type='text'
						placeholder='Enter SteamID'
						value={steamID}
						onChange={(e) => setSteamID(e.target.value)}
						className='w-full px-4 py-2 rounded-md'
						style={{
							backgroundColor: '#0F172A',
							color: '#FFFFFF',
							borderColor: '#334155',
						}}
					/>
				)}

				<button
					type='submit'
					className='w-full px-4 py-2 rounded-md'
					style={{
						backgroundColor: 'rgb(239, 68, 68)', // Red button
						color: '#FFFFFF',
						border: 'none',
						transition: 'background-color 0.3s ease',
					}}
				>
					Search
				</button>
			</form>

			{error && <p className='text-red-500'>{error}</p>}

			{results && (
				<div className='territory-results space-y-6'>
					<div
						className='territory p-4 rounded-lg'
						style={{
							backgroundColor: '#0F172A',
							color: '#FFFFFF',
						}}
					>
						<h3 className='text-lg font-semibold mb-2'>
							Territory
						</h3>
						<p className='flex justify-between items-center'>
							<span>GUID</span>
							<span className='font-mono'>
								{results.territory.GUID}
							</span>
						</p>
						<p
							className='coords flex justify-between items-center cursor-pointer hover:text-blue-600'
							onClick={() =>
								handleCopyCoordinates(
									results.territory.Pos_x,
									results.territory.Pos_y,
									results.territory.Pos_z
								)
							}
							title='Click to copy coordinates'
						>
							<span>Coordinates</span>
							<span className='font-mono'>
								{Math.floor(results.territory.Pos_x)} x{' '}
								{Math.floor(results.territory.Pos_z)} (Y:{' '}
								{Math.floor(results.territory.Pos_y)})
							</span>
						</p>
						<p className='flex justify-between items-center'>
							<span>Admin</span>
							<span>
								{results.territory.Members.length > 0
									? results.territory.Members[0].Name
									: 'No Admin Found'}
							</span>
						</p>
					</div>

					<div className='members p-4 rounded-lg'>
						<h3 className='text-lg font-semibold mb-2'>Members</h3>
						<div className='space-y-2'>
							{results.territory.Members.length > 0 ? (
								results.territory.Members.map(
									(member, index) => (
										<p
											key={index}
											className='cursor-pointer hover:text-blue-600 flex justify-between items-center'
											onClick={() =>
												handleCopy(member.UID)
											}
											title='Click to copy Steam ID'
										>
											<span>{member.Name}</span>
											<span className='font-mono'>
												({member.UID})
											</span>
										</p>
									)
								)
							) : (
								<p>No members found</p>
							)}
						</div>
					</div>

					{results.groups && (
						<div className='groups p-4 rounded-lg'>
							<h3 className='text-lg font-semibold mb-2'>
								Groups
							</h3>
							<p>
								All members are in group:{' '}
								{results.uniqueGroups.length > 1
									? results.uniqueGroups.join(', ')
									: results.uniqueGroups[0]}
							</p>
						</div>
					)}

					{copied && (
						<p className='text-green-500'>Copied: {copied}</p>
					)}
				</div>
			)}

			{/* Conditionally render PlayersNear component only if the results are available */}
			{results && results.territory && (
				<div className='playersNear'>
					<TerritoryEvents
						server={server}
						territoryGUID={results.territory.GUID}
					/>
					<PlayersNear
						server={server}
						territoryPosX={results.territory.Pos_x}
						territoryPosZ={results.territory.Pos_z}
						excludePlayers={results.territory.Members.map(
							(member) => member.UID
						)}
					/>
					<ItemsTaken
						server={server}
						territoryPosX={results.territory.Pos_x}
						territoryPosZ={results.territory.Pos_z}
					/>
					<DupedItemsTerritoryCheck
						server={server}
						territoryPosX={results.territory.Pos_x}
						territoryPosZ={results.territory.Pos_z}
					/>
					<NearbyLoginsCheck
						server={server}
						territoryPosX={results.territory.Pos_x}
						territoryPosZ={results.territory.Pos_z}
						excludePlayers={results.territory.Members.map(
							(member) => member.UID
						)}
					/>
				</div>
			)}
		</div>
	);
};

export default TerritoryCheck;
