import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	TextField,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const TerritoryNotPaid = ({ server }) => {
	const [territories, setTerritories] = useState([]);
	const [error, setError] = useState('');
	const [searchTerm, setSearchTerm] = useState(''); // State for search input
	const [filteredTerritories, setFilteredTerritories] = useState([]); // Filtered results

	useEffect(() => {
		const fetchTerritories = async () => {
			try {
				const token = localStorage.getItem('authToken');
				const response = await axios.get(
					`${process.env.REACT_APP_BACKEND_URL}/api/${server}/territories-not-paid`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setTerritories(response.data);
				setFilteredTerritories(response.data); // Initially, show all territories
			} catch (err) {
				setError('Failed to fetch unpaid territories');
			}
		};

		fetchTerritories();
	}, [server]);

	// Handle search input change
	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchTerm(value);

		if (value) {
			// Filter territories based on the search term matching AdminUIDs
			const filtered = territories.filter((territory) =>
				territory.AdminUIDS.some((uid) =>
					uid.toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilteredTerritories(filtered);
		} else {
			// If no search term, reset to show all territories
			setFilteredTerritories(territories);
		}
	};

	return (
		<div
			className='w-full max-w-xl mx-auto p-4 rounded-lg shadow-lg'
			style={{
				backgroundColor: '#1E293B', // Darker background color
				color: '#FFFFFF',
			}}
		>
			<Typography
				variant='h5'
				className='mb-8 text-center font-bold'
				style={{
					color: '#FFFFFF',
				}}
			>
				List of Territories Not Paid For
			</Typography>

			{/* Search input */}
			<TextField
				label='Search by Admin UID'
				variant='outlined'
				fullWidth
				value={searchTerm}
				onChange={handleSearchChange}
				className='mb-6'
				placeholder='Enter Admin UID'
				style={{
					backgroundColor: '#0F172A',
					color: '#FFFFFF',
					borderColor: '#334155',
				}}
				sx={{
					input: {
						color: '#FFFFFF',
					},
					label: {
						color: '#FFFFFF',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: '#334155',
						},
						'&:hover fieldset': {
							borderColor: '#475569',
						},
						'&.Mui-focused fieldset': {
							borderColor: '#475569',
						},
					},
					marginBottom: '24px',
				}}
			/>

			<div className='container mt-8'>
				{error && <Typography color='error'>{error}</Typography>}

				{filteredTerritories.length > 0 ? (
					filteredTerritories.map((territory, index) => (
						<Accordion
							key={index}
							className='mt-2 rounded-lg shadow-sm transition-shadow duration-300'
							sx={{
								backgroundColor: '#0F172A',
								color: '#FFFFFF',
								marginBottom: '12px',
								border: '1px solid #334155',
							}}
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />
								}
								sx={{
									backgroundColor: '#1E293B',
									padding: '12px',
									'&:hover': {
										backgroundColor: '#2C3E50',
									},
								}}
							>
								<Typography
									variant='h6'
									className='font-semibold'
									style={{
										color: '#FFFFFF',
									}}
								>
									Territory GUID: {territory.GUID}
								</Typography>
							</AccordionSummary>
							<AccordionDetails
								className='rounded-b-lg p-4'
								style={{
									backgroundColor: '#0F172A',
								}}
							>
								<Typography className='mb-2 text-sm'>
									<strong>Coordinates:</strong> X:{' '}
									{territory.Pos_x}, Z: {territory.Pos_z}
								</Typography>
								<Typography className='mb-2 text-sm'>
									<strong>Last Paid:</strong>{' '}
									{territory.LastPaid}
								</Typography>
								<Typography className='mb-2 text-sm'>
									<strong>Minutes Since Paid:</strong>{' '}
									{territory.MinutesSincePaid}
								</Typography>
								<Typography className='mb-2 text-sm'>
									<strong>Admin UIDs:</strong>{' '}
									{territory.AdminUIDS.join(', ')}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))
				) : (
					<Typography className='text-gray-400'>
						No unpaid territories found for the given Admin UID.
					</Typography>
				)}
			</div>
		</div>
	);
};

export default TerritoryNotPaid;
