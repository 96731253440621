import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PlayersNear = ({
	server,
	territoryPosX,
	territoryPosZ,
	excludePlayers,
}) => {
	const [players, setPlayers] = useState([]);
	const [filteredPlayers, setFilteredPlayers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	// Fetch players logic
	const fetchPlayers = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('authToken');
			const excludePlayersParam = excludePlayers.join(',');

			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/${server}/players-near/${territoryPosX}/${territoryPosZ}?excludePlayers=${excludePlayersParam}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200 && response.data.players) {
				const sortedPlayers = response.data.players.sort((a, b) => {
					const fullDateA = new Date(
						`${a.date.split(' | ').join('T')}`
					);
					const fullDateB = new Date(
						`${b.date.split(' | ').join('T')}`
					);
					return fullDateB - fullDateA;
				});
				setPlayers(sortedPlayers);
				setFilteredPlayers(sortedPlayers);
			} else {
				setPlayers([]);
				setFilteredPlayers([]);
			}
		} catch (err) {
			setError('An error occurred fetching nearby players');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (territoryPosX && territoryPosZ) {
			fetchPlayers();
		}
	}, [server, territoryPosX, territoryPosZ]);

	const handleDateChange = (date) => {
		setSelectedDate(date);
		const filtered = players.filter((player) => {
			const [dates] = player.date.split(' | ');
			const [year, month, day] = dates.split('-');
			const playerDate = new Date(`${year}-${month}-${day}`);
			return playerDate.toDateString() === date.toDateString();
		});
		setFilteredPlayers(filtered);
	};

	const handleReset = () => {
		setSelectedDate(null);
		setFilteredPlayers(players);
	};

	const formatDateTime = (date) => {
		const [dates, time] = date.split(' | ');
		const [year, month, day] = dates.split('-');
		return `${day}-${month}-${year} | ${time}`;
	};

	return (
		<div className='my-4'>
			<h2
				onClick={toggleAccordion}
				className='cursor-pointer p-4 rounded'
				style={{
					backgroundColor: '#2C3748', // Lighter than main background for the accordion header
					color: '#FFFFFF',
					transition: 'background-color 0.3s',
				}}
				onMouseEnter={(e) =>
					(e.target.style.backgroundColor = '#374151')
				}
				onMouseLeave={(e) =>
					(e.target.style.backgroundColor = '#2C3748')
				}
			>
				Players Near Territory (Click to {isOpen ? 'Close' : 'Open'})
			</h2>

			{isOpen && (
				<div
					className='accordion-content p-4'
					style={{
						backgroundColor: '#0F172A', // Accordion content background
						color: '#FFFFFF',
						borderRadius: '8px',
					}}
				>
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						dateFormat='dd-MM-yyyy'
						className='border px-4 py-2 mb-4 w-full rounded'
						placeholderText='Filter by date'
						style={{
							backgroundColor: '#1E293B',
							borderColor: '#334155',
						}}
						popperClassName='custom-datepicker' // Optional, for further customization of the date picker popup
						customInput={
							<input
								style={{
									backgroundColor: '#1E293B',
									color: '#FFFFFF',
									borderColor: '#334155',
									padding: '10px',
									borderRadius: '4px',
									width: '100%',
								}}
							/>
						}
					/>
					<button
						className='bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-300 mb-4'
						onClick={handleReset}
					>
						Reset
					</button>

					{loading && <p>Loading...</p>}
					{error && <p className='text-red-500'>{error}</p>}
					{filteredPlayers.length > 0 ? (
						<table className='min-w-full bg-gray-800 rounded-lg'>
							<thead>
								<tr style={{ backgroundColor: '#1E293B' }}>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Date
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Steam ID
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Coordinates
									</th>
								</tr>
							</thead>
							<tbody>
								{filteredPlayers.map((player, index) => (
									<tr
										key={index}
										className='hover:bg-gray-700 transition-all'
									>
										<td className='py-2 px-4 border-b border-gray-700'>
											{formatDateTime(player.date)}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{player.playerName} (
											{player.steamID})
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{player.coordinates.posX},{' '}
											{player.coordinates.posZ}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<p className='text-gray-500'>
							No players found near the territory.
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default PlayersNear;
