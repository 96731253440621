import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

const FleaMarketPage = () => {
	const [steamId, setSteamId] = useState('');
	const [listings, setListings] = useState([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [hideClaimed, setHideClaimed] = useState(false);
	const [hasSearched, setHasSearched] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const listingsPerPage = 10;

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleSearch = async () => {
		setError('');
		setListings([]);
		setLoading(true);
		setHasSearched(true);

		try {
			const token = localStorage.getItem('authToken');
			const response = await axios.get(
				`${backendUrl}/api/fleamarket/listings/${steamId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'User-Agent': null,
					},
				}
			);
			setListings(response.data.Listings);
			setCurrentPage(1); // Reset page to 1 on a new search
		} catch (err) {
			setError('Failed to fetch listings. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	const filteredListings = hideClaimed
		? listings.filter((listing) => !listing.Claimed)
		: listings;

	const checkExpiredClaim = (listing) => {
		if (!listing.Claimed && listing.must_be_claimed_by_raw) {
			const claimDate = new Date(listing.must_be_claimed_by_raw);
			const currentDate = new Date();
			return currentDate > claimDate;
		}
		return false;
	};

	// Reset page when hideClaimed changes
	const handleHideClaimedChange = (e) => {
		setHideClaimed(e.target.checked);
		setCurrentPage(1); // Reset page to 1 when the filter is applied or removed
	};

	// Pagination logic
	const totalPages = Math.ceil(filteredListings.length / listingsPerPage);
	const currentPageListings = filteredListings.slice(
		(currentPage - 1) * listingsPerPage,
		currentPage * listingsPerPage
	);

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<Box
			className='p-4'
			style={{ backgroundColor: '#0F172A', color: '#FFFFFF' }}
		>
			<Typography
				variant='h4'
				className='mb-4'
				style={{ marginBottom: '24px' }}
			>
				Flea Market Listings
			</Typography>
			<TextField
				label='Steam ID'
				value={steamId}
				onChange={(e) => setSteamId(e.target.value)}
				className='mb-4 mt-8'
				InputLabelProps={{
					style: { color: '#FFFFFF' },
				}}
				InputProps={{
					style: { color: '#FFFFFF' },
				}}
				fullWidth
				style={{
					backgroundColor: '#1E293B',
					borderRadius: '4px',
					marginBottom: '16px',
					marginTop: '16px',
				}}
			/>
			<Button
				variant='contained'
				onClick={handleSearch}
				style={{
					backgroundColor: '#3B82F6',
					color: '#FFFFFF',
					width: '100%',
					marginBottom: '16px',
				}}
				disabled={loading}
			>
				{loading ? 'Searching...' : 'Search Listings'}
			</Button>

			{/* Conditionally render the checkbox if there are listings */}
			{listings.length > 0 && (
				<FormControlLabel
					control={
						<Checkbox
							checked={hideClaimed}
							onChange={handleHideClaimedChange}
							style={{ color: '#FFFFFF' }}
						/>
					}
					label='Hide Claimed'
					style={{ color: '#FFFFFF', marginBottom: '16px' }}
				/>
			)}

			{error && (
				<Typography variant='body1' color='error' className='mt-4'>
					{error}
				</Typography>
			)}

			<Box className='mt-6'>
				{hasSearched && currentPageListings.length === 0 ? (
					<Typography variant='body1' className='mt-4'>
						No listings found.
					</Typography>
				) : (
					<>
						<table className='min-w-full bg-gray-800 rounded-lg'>
							<thead>
								<tr>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Active
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Claimed
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Player Name
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Steam ID
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Item Class
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Price
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Created At
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Expires
									</th>
									<th className='py-2 px-4 border-b border-gray-700 text-left'>
										Expired Claim
									</th>
								</tr>
							</thead>
							<tbody>
								{currentPageListings.map((listing, index) => (
									<tr
										key={index}
										className='hover:bg-gray-700 transition-all'
									>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.Active ? 'Yes' : 'No'}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.Claimed ? 'Yes' : 'No'}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.PlayerName}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.SteamId}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.ItemClassName}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.Price}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{new Date(
												listing.CreatedAt
											).toLocaleDateString()}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{listing.expires_at_human}
										</td>
										<td className='py-2 px-4 border-b border-gray-700'>
											{checkExpiredClaim(listing)
												? 'Yes'
												: 'No'}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{/* Pagination Controls */}
						<Box
							display='flex'
							justifyContent='center'
							mt={4}
							className='pagination'
						>
							<Button
								onClick={handlePreviousPage}
								disabled={currentPage === 1}
								style={{ marginRight: '8px' }}
							>
								Previous
							</Button>
							<Typography
								variant='body1'
								style={{ color: '#FFFFFF', margin: '0 16px' }}
							>
								Page {currentPage} of {totalPages}
							</Typography>
							<Button
								onClick={handleNextPage}
								disabled={currentPage === totalPages}
							>
								Next
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default FleaMarketPage;
