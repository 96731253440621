import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const DupedItemsTerritoryCheck = ({ server, territoryPosX, territoryPosZ }) => {
	const [results, setResults] = useState([]);
	const [error, setError] = useState('');
	const [selectedDate, setSelectedDate] = useState('');
	const [selectedHour, setSelectedHour] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	// Calculate the total amount of duped items
	const totalDupedCount = results.reduce(
		(total, item) => total + (item.count || 0),
		0
	);

	// Toggle accordion visibility
	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	const handleCheckDupedItems = async () => {
		setError('');
		const token = localStorage.getItem('authToken');
		if (!token) {
			setError('Authorization token is missing. Please log in again.');
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/dupe-check-radius`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						server,
						date: selectedDate,
						hour: selectedHour,
						posX: territoryPosX,
						posZ: territoryPosZ,
						radius: 25,
					}),
				}
			);

			if (!response.ok) {
				throw new Error(`Server error: ${response.statusText}`);
			}

			const data = await response.json();

			// Sort the results by 'count' in descending order
			const sortedData = data.sort(
				(a, b) => (b.count || 0) - (a.count || 0)
			);
			setResults(sortedData); // Populate sorted results
		} catch (err) {
			setError(`Failed to fetch results: ${err.message}`);
		}
	};

	return (
		<div className='my-4'>
			<h2
				onClick={toggleAccordion}
				className='cursor-pointer p-4 rounded'
				style={{
					backgroundColor: '#2C3748',
					color: '#FFFFFF',
					transition: 'background-color 0.3s',
				}}
				onMouseEnter={(e) =>
					(e.target.style.backgroundColor = '#374151')
				}
				onMouseLeave={(e) =>
					(e.target.style.backgroundColor = '#2C3748')
				}
			>
				Duped Items Check (Click to {isOpen ? 'Close' : 'Open'})
			</h2>

			{isOpen && (
				<div
					className='accordion-content p-4'
					style={{
						backgroundColor: '#0F172A',
						color: '#FFFFFF',
						borderRadius: '8px',
					}}
				>
					<Box className='flex flex-col space-y-4 mt-8'>
						<TextField
							id='date'
							label='Select Date'
							type='date'
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
								style: { color: '#D1D5DB' },
							}}
							className='w-full'
							sx={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								borderRadius: '8px',
								'& .MuiInputBase-input': {
									color: '#FFFFFF',
								},
							}}
						/>

						<TextField
							id='hour'
							select
							label='Select Hour'
							value={selectedHour}
							onChange={(e) => setSelectedHour(e.target.value)}
							className='w-full'
							sx={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								borderRadius: '8px',
								'& .MuiInputBase-input': {
									color: '#FFFFFF',
								},
								'& .MuiSelect-icon': {
									color: '#FFFFFF',
								},
							}}
							InputLabelProps={{
								style: { color: '#D1D5DB' },
							}}
						>
							{[
								'00',
								'03',
								'06',
								'09',
								'12',
								'15',
								'18',
								'21',
							].map((hour) => (
								<MenuItem
									key={hour}
									value={hour}
									style={{
										backgroundColor: '#0F172A',
										color: '#FFFFFF',
									}}
								>
									{`${hour}:00`}
								</MenuItem>
							))}
						</TextField>

						<Button
							variant='contained'
							onClick={handleCheckDupedItems}
							className='mt-4'
							sx={{
								backgroundColor: 'rgb(239, 68, 68)',
								'&:hover': {
									backgroundColor: 'rgb(220, 38, 38)',
								},
								color: '#FFFFFF',
							}}
						>
							Check Duped Items
						</Button>
					</Box>

					{error && (
						<Typography
							variant='body2'
							color='error'
							className='mt-4'
						>
							{error}
						</Typography>
					)}

					{/* Display total duped count */}
					{results.length > 0 && (
						<Typography
							variant='h6'
							className='text-center mt-4 font-bold'
							style={{ color: '#FFFFFF' }}
						>
							Amount duped = {totalDupedCount}
						</Typography>
					)}

					{results.length > 0 ? (
						results.map((result, index) => (
							<Box
								key={index}
								className='p-2 mb-2 border rounded-md'
								style={{
									backgroundColor: '#0F172A',
									color: '#FFFFFF',
									borderColor: '#334155',
								}}
							>
								<Typography
									variant='body1'
									className='font-bold'
								>
									GUID: {result.guid}
								</Typography>
								<Typography variant='body2'>
									Item: {result.item}
								</Typography>
								<Typography variant='body2'>
									Count: {result.count}
								</Typography>
								<Typography variant='body2' className='mt-1'>
									Positions:
									{result.positions.map(
										(position, posIndex) => (
											<div key={posIndex}>{position}</div>
										)
									)}
								</Typography>
							</Box>
						))
					) : (
						<Typography
							variant='body2'
							className='text-center'
							style={{ color: '#D1D5DB' }}
						>
							No duped items found in the territory or select a
							date and hour to check.
						</Typography>
					)}
				</div>
			)}
		</div>
	);
};

export default DupedItemsTerritoryCheck;
