import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AlertComponent = () => {
	const [newAccounts, setNewAccounts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		fetchNewAccounts();
	}, []);

	const fetchNewAccounts = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('authToken');
			const response = await axios.get(
				`${backendUrl}/api/alt-accounts/new`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setNewAccounts(response.data);
		} catch (err) {
			setError('Failed to fetch new alt accounts');
		} finally {
			setLoading(false);
		}
	};

	const handleMarkAsSeen = async (playerId) => {
		const idsToMarkAsSeen = newAccounts
			.find((account) => account.player_unique_identifier_id === playerId)
			.steam_ids.filter((id) => id.isNew)
			.map((id) => id.id);

		try {
			const token = localStorage.getItem('authToken');
			await axios.post(
				`${backendUrl}/api/alt-accounts/mark-seen`,
				{ ids: idsToMarkAsSeen },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setNewAccounts((prevAccounts) =>
				prevAccounts.map((account) =>
					account.player_unique_identifier_id === playerId
						? {
								...account,
								steam_ids: account.steam_ids.map((idObj) =>
									idsToMarkAsSeen.includes(idObj.id)
										? { ...idObj, isNew: false }
										: idObj
								),
						  }
						: account
				)
			);
		} catch (err) {
			setError('Failed to mark as seen');
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: '#1E293B',
				color: '#FFFFFF',
				p: 3,
				borderRadius: '8px',
				marginTop: '20px',
			}}
		>
			<Typography variant='h6'>New Alt Accounts Alerts</Typography>
			{loading ? (
				<Typography>Loading...</Typography>
			) : error ? (
				<Typography style={{ color: '#EF4444' }}>{error}</Typography>
			) : newAccounts.length > 0 ? (
				<>
					{newAccounts.map((account, idx) => (
						<Box
							key={idx}
							sx={{
								my: 2,
								p: 2,
								backgroundColor: '#0F172A',
								borderRadius: '8px',
							}}
						>
							<Typography variant='subtitle1'>
								Player ID: {account.player_unique_identifier_id}
							</Typography>
							<Typography
								variant='subtitle2'
								style={{ color: '#9CA3AF' }}
							>
								Original Ban: {account.original_ban || 'N/A'}
							</Typography>
							<Box sx={{ pl: 2 }}>
								{account.steam_ids.map(
									(idObj, idIdx) =>
										idObj.isNew && (
											<Typography key={idIdx}>
												New Steam ID: {idObj.id}
											</Typography>
										)
								)}
							</Box>
							<Button
								variant='contained'
								color='primary'
								onClick={() =>
									handleMarkAsSeen(
										account.player_unique_identifier_id
									)
								}
								sx={{ mt: 1 }}
							>
								Mark as Handled
							</Button>
						</Box>
					))}
				</>
			) : (
				<Typography>No new alt accounts found</Typography>
			)}
		</Box>
	);
};

export default AlertComponent;
