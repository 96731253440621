import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaCopy, FaExternalLinkAlt, FaUser } from 'react-icons/fa';
import '../assets/scss/userSearch.scss';

const UserSearch = ({ server, onGroupClick, preloadedPlayer }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [results, setResults] = useState(null);
	const [groupActivity, setGroupActivity] = useState([]);
	const [loadingGroupActivity, setLoadingGroupActivity] = useState(false);
	const [error, setError] = useState('');
	const [cftoolsProfileLink, setCftoolsProfileLink] = useState(null);
	const [pollingAttempts, setPollingAttempts] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isAutoSearch, setIsAutoSearch] = useState(false);

	const token = localStorage.getItem('authToken');
	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	// Automatically set the player name and trigger search if preloadedPlayer exists
	useEffect(() => {
		if (preloadedPlayer) {
			setSearchTerm(preloadedPlayer);
			setIsAutoSearch(true);
		}
	}, [preloadedPlayer]);

	useEffect(() => {
		if (isAutoSearch && searchTerm) {
			handleSearch();
		}
	}, [searchTerm, isAutoSearch]);

	const handleSearch = async (e) => {
		e && e.preventDefault();
		setError('');
		setResults(null);
		setCftoolsProfileLink(null);
		setGroupActivity([]);
		setLoading(true);

		try {
			const response = await axios.get(
				`${backendUrl}/api/users/${server}/user-search/${searchTerm}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setResults(response.data);

			if (response.data.steamID) {
				await fetchCftoolsProfile(response.data.steamID);
				fetchGroupActivity(response.data.steamID);
			}
		} catch (err) {
			setError('No results found');
		} finally {
			setLoading(false);
			setLoadingGroupActivity(false);
		}
	};

	const fetchCftoolsProfile = async (steamID) => {
		try {
			const response = await axios.get(
				`${backendUrl}/api/cftools/profile/${steamID}`
			);
			if (response.data.cftools_id) {
				const profileLink = `https://app.cftools.cloud/profile/${response.data.cftools_id}`;
				setCftoolsProfileLink(profileLink);
				setPollingAttempts(0);
			}
		} catch (error) {
			if (error.response && error.response.status === 429) {
				if (pollingAttempts < 5) {
					setPollingAttempts(pollingAttempts + 1);
					setTimeout(() => fetchCftoolsProfile(steamID), 5000);
				} else {
					console.error('Max polling attempts reached.');
					setError(
						'Unable to fetch CFTools profile. Try again later.'
					);
				}
			} else {
				console.error('Error fetching CFTools profile:', error);
			}
		}
	};

	const fetchGroupActivity = async (steamID) => {
		try {
			const response = await axios.get(
				`${backendUrl}/api/users/${server}/group-activity/${steamID}`
			);
			setGroupActivity(response.data.groupActivity);
		} catch (err) {
			setGroupActivity([]);
			console.error('Error fetching group activity:', err);
		} finally {
			setLoadingGroupActivity(false);
		}
	};

	const handleCopy = (steamID) => {
		navigator.clipboard.writeText(steamID).then(() => {
			alert('Steam ID copied to clipboard!');
		});
	};

	return (
		<div className='flex justify-center items-center'>
			<div
				className='w-full max-w-2xl rounded-lg shadow-md p-6'
				style={{
					backgroundColor: '#0F172A',
					color: '#FFFFFF',
				}}
			>
				<h2 className='text-2xl font-semibold mb-4'>User Info</h2>

				<form onSubmit={handleSearch} className='mb-6'>
					<div className='relative'>
						<input
							type='text'
							placeholder='Enter steam ID or name'
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
								setIsAutoSearch(false);
							}}
							className='w-full p-3 border border-gray-700 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
							style={{
								backgroundColor: '#1E293B',
								color: '#FFFFFF',
								borderColor: '#334155',
							}}
						/>
						<button
							type='submit'
							className='absolute right-2 top-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300'
						>
							Search
						</button>
					</div>
				</form>

				{error && <p className='text-red-500'>{error}</p>}
				{loading && <p>Loading...</p>}
				{results && (
					<div className='results space-y-6'>
						<div>
							<h3 className='text-lg font-semibold'>
								Steam ID{' '}
								<FaUser className='inline-block ml-1' />
							</h3>
							<p
								className='text-blue-500 hover:underline cursor-pointer'
								onClick={() => handleCopy(results.steamID)}
							>
								<FaCopy className='inline-block mr-2' />
								{results.steamID} (Click to copy)
							</p>
							{cftoolsProfileLink && (
								<p>
									<a
										href={cftoolsProfileLink}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 hover:underline flex items-center'
									>
										<FaExternalLinkAlt className='mr-1' />
										View CFTools Profile
									</a>
								</p>
							)}
						</div>

						{/* Group Info */}
						<div>
							<h3 className='text-lg font-semibold'>
								Group Info
							</h3>
							{results.groups.length > 0 ? (
								results.groups.map((group, index) => (
									<div key={index}>
										<p>
											{group.member} is part of the group{' '}
											<span
												className='text-blue-500 hover:underline cursor-pointer'
												onClick={() =>
													onGroupClick(group.group)
												}
											>
												{group.group}
											</span>
										</p>
									</div>
								))
							) : (
								<p>No group found.</p>
							)}
						</div>

						{/* Territory Info */}
						<div>
							<h3 className='text-lg font-semibold'>
								Territory Info
							</h3>
							{results.territories.length > 0 ? (
								results.territories.map((territory, index) => (
									<div key={index}>
										<p>
											{territory.member} is part of the
											territory {territory.territory}
										</p>
									</div>
								))
							) : (
								<p>No territory found.</p>
							)}
						</div>

						{/* Group Activity Section */}
						<div>
							<h3 className='text-lg font-semibold'>
								Group Activity
							</h3>
							{loadingGroupActivity ? (
								<p>Loading group activity...</p>
							) : groupActivity.length > 0 ? (
								groupActivity.map((activity, index) => (
									<div key={index}>
										<p>
											{activity.time} - {activity.action}{' '}
											group {activity.group}
										</p>
									</div>
								))
							) : (
								<p>No group activity found.</p>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserSearch;
