import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { FaDownload, FaSyncAlt, FaTrashAlt, FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Administrate = () => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleRegister = () => {
		navigate('/register');
	};

	const handleFTPDownload = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('authToken');
			await axios.post(
				`${backendUrl}/api/run-ftp-downloads`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			setMessage('FTP downloads started successfully!');
		} catch (error) {
			console.error('Error triggering FTP download:', error);
			setMessage('Failed to start FTP downloads.');
		} finally {
			setLoading(false);
		}
	};

	const handleManualUpdate = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('authToken');
			await axios.post(
				`${backendUrl}/api/alt-accounts/update`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			setMessage('Alt accounts updated successfully!');
		} catch (error) {
			console.error('Error updating alt accounts:', error);
			setMessage('Failed to update alt accounts.');
		} finally {
			setLoading(false);
		}
	};

	const handleWipeFiles = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('authToken');
			await axios.post(
				`${backendUrl}/api/wipe-files`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			setMessage('Files wiped successfully!');
		} catch (error) {
			console.error('Error wiping files:', error);
			setMessage('Failed to wipe files.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box
			sx={{
				padding: '20px',
				color: '#FFFFFF',
				backgroundColor: '#1E293B',
			}}
		>
			<Typography variant='h4' gutterBottom>
				Administration Panel
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					maxWidth: '400px',
					margin: 'auto',
				}}
			>
				<Button
					variant='contained'
					startIcon={<FaUserPlus />}
					onClick={handleRegister}
					style={{ backgroundColor: '#2D3748', color: '#FFFFFF' }}
				>
					Register User
				</Button>
				<Button
					variant='contained'
					startIcon={<FaDownload />}
					onClick={handleFTPDownload}
					style={{ backgroundColor: '#2D3748', color: '#FFFFFF' }}
					disabled={loading}
				>
					{loading ? 'Starting Download...' : 'FTP Download'}
				</Button>
				<Button
					variant='contained'
					startIcon={<FaSyncAlt />}
					onClick={handleManualUpdate}
					style={{ backgroundColor: '#2D3748', color: '#FFFFFF' }}
					disabled={loading}
				>
					{loading ? 'Updating...' : 'Manual Update'}
				</Button>
				<Button
					variant='contained'
					startIcon={<FaTrashAlt />}
					onClick={handleWipeFiles}
					style={{ backgroundColor: '#EF4444', color: '#FFFFFF' }}
					disabled={loading}
				>
					{loading ? 'Wiping Files...' : 'Wipe Files'}
				</Button>
			</Box>
			{message && (
				<Typography
					variant='body1'
					style={{ color: '#00FF00', marginTop: '20px' }}
				>
					{message}
				</Typography>
			)}
		</Box>
	);
};

export default Administrate;
