// App.js

import { Box, CssBaseline } from '@mui/material';
import { jwtDecode } from 'jwt-decode'; // Correct import for JWT decoding
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './assets/scss/main.scss';
import AltAccountCheck from './components/AltAccountCheck';
import LoginComponent from './components/LoginComponent';
import MenuBar from './components/MenuBar';
import Register from './components/Register';
import Administrate from './pages/Administrate';
import ServerPage from './pages/ServerPage';
import UserSearchAllServers from './pages/UserSearchAllServers';
import FleaMarketPage from './pages/fleamarket/FleaMarketPage';

const App = () => {
	const [loading, setLoading] = useState(false);
	const [authToken, setAuthToken] = useState(
		localStorage.getItem('authToken')
	);
	const [userRole, setUserRole] = useState(null);
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		const timer = setTimeout(() => setLoading(false), 300);
		return () => clearTimeout(timer);
	}, [location]);

	useEffect(() => {
		if (authToken) {
			const decodedToken = jwtDecode(authToken);
			setUserRole(decodedToken.role);
		}
	}, [authToken]);

	if (!authToken && location.pathname !== '/login') {
		return <Navigate to='/login' />;
	}

	return (
		<>
			<CssBaseline />
			<div
				style={{
					display: 'flex',
					backgroundColor: '#1E293B',
				}}
			>
				{authToken && <MenuBar userRole={userRole} />}
				<Box
					sx={{
						flex: 1,
						padding: '20px',
						backgroundColor: '#1E293B',
					}}
				>
					{loading ? (
						<div>Loading...</div>
					) : (
						<Routes>
							<Route
								path='/login'
								element={
									<LoginComponent
										setAuthToken={setAuthToken}
									/>
								}
							/>
							{authToken && (
								<>
									<Route
										path='/'
										element={<UserSearchAllServers />}
									/>
									<Route
										path='/user-search-all-servers'
										element={<UserSearchAllServers />}
									/>
									<Route
										path='/alt-account-check'
										element={<AltAccountCheck />}
									/>
									<Route
										path='/flea-market'
										element={<FleaMarketPage />}
									/>
									<Route
										path='/:server'
										element={<ServerPage />}
									/>
									{userRole === 'sysadm' && (
										<>
											<Route
												path='/administrate'
												element={<Administrate />}
											/>
											<Route
												path='/register'
												element={<Register />}
											/>
										</>
									)}
								</>
							)}
						</Routes>
					)}
				</Box>
			</div>
		</>
	);
};

export default App;
