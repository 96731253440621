import { Box, Button } from '@mui/material';
import React, { useState } from 'react';

const ButtonMenu = ({ setSelectedComponent }) => {
	const [activeButton, setActiveButton] = useState('UserSearch');

	const handleButtonClick = (componentName) => {
		setSelectedComponent(componentName);
		setActiveButton(componentName);
	};

	const menuItems = [
		{ name: 'User search', value: 'UserSearch' },
		{ name: 'Group check', value: 'GroupCheck' },
		{ name: 'Territory check', value: 'TerritoryCheck' },
		// { name: 'Search alt account', value: 'AltAccountCheck' },
		{
			name: 'Group / territory limit search',
			value: 'GroupTerritoryLimitSearch',
		},
		{ name: 'Check Duplicated Items', value: 'DupedItemsCheck' },
		{ name: 'Check Territories Not Paid', value: 'TerritoryNotPaid' },
	];

	return (
		<Box className='button-menu-container flex flex-col space-y-2'>
			{menuItems.map((item) => (
				<Button
					key={item.value}
					variant={
						activeButton === item.value ? 'contained' : 'outlined'
					}
					onClick={() => handleButtonClick(item.value)}
					sx={{
						color:
							activeButton === item.value ? '#FFFFFF' : '#9CA3AF', // White text for active, light gray for others
						backgroundColor:
							activeButton === item.value
								? 'rgb(59, 130, 246)'
								: '#0F172A', // Active color or dark blue
						borderColor: '#334155', // Border for the outlined buttons
						borderRadius: '8px',
						'&:hover': {
							backgroundColor:
								activeButton === item.value
									? 'rgb(37, 99, 235)' // Darker blue on hover when active
									: '#1E293B', // Darker background for non-active on hover
						},
						transition: 'background-color 0.3s ease',
						padding: '10px 16px', // Adjust padding for better button size
					}}
				>
					{item.name}
				</Button>
			))}
		</Box>
	);
};

export default ButtonMenu;
