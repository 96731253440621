import axios from 'axios';
import React, { useEffect, useState } from 'react';

const TerritoryEvents = ({ server, territoryGUID }) => {
	const [events, setEvents] = useState([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchEvents = async () => {
			setLoading(true);
			setError('');

			try {
				const token = localStorage.getItem('authToken');
				const response = await axios.get(
					`${process.env.REACT_APP_BACKEND_URL}/api/${server}/territory-logs/${territoryGUID}`,
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				setEvents(response.data.events);
			} catch (err) {
				setError('Error fetching territory events');
			} finally {
				setLoading(false);
			}
		};

		fetchEvents();
	}, [server, territoryGUID]);

	const formatTimestampToUKTime = (isoString) => {
		const date = new Date(isoString);
		return date
			.toLocaleString('en-GB', {
				timeZone: 'Europe/London', // Use UK timezone
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: false,
			})
			.replace(',', '')
			.replace(' ', ' - ');
	};

	if (loading) return <p>Loading...</p>;
	if (error) return <p className='text-red-500'>{error}</p>;

	return (
		<div className='my-4'>
			<h2
				className='p-4 rounded cursor-pointer'
				style={{
					backgroundColor: '#2C3748',
					color: '#FFFFFF',
					transition: 'background-color 0.3s',
				}}
			>
				Territory Events
			</h2>

			<div
				className='p-4'
				style={{
					backgroundColor: '#0F172A',
					color: '#FFFFFF',
					borderRadius: '8px',
				}}
			>
				<table className='min-w-full bg-gray-800 rounded-lg'>
					<thead>
						<tr style={{ backgroundColor: '#1E293B' }}>
							<th className='py-2 px-4 border-b border-gray-700 text-left'>
								Timestamp
							</th>
							<th className='py-2 px-4 border-b border-gray-700 text-left'>
								Player
							</th>
							<th className='py-2 px-4 border-b border-gray-700 text-left'>
								Action
							</th>
							<th className='py-2 px-4 border-b border-gray-700 text-left'>
								Kicked By
							</th>
						</tr>
					</thead>
					<tbody>
						{events.map((event, index) => (
							<tr
								key={index}
								className='hover:bg-gray-700 transition-all'
							>
								<td className='py-2 px-4 border-b border-gray-700'>
									{formatTimestampToUKTime(event.timestamp)}
								</td>
								<td className='py-2 px-4 border-b border-gray-700'>
									{event.playerName} ({event.steamID})
								</td>
								<td className='py-2 px-4 border-b border-gray-700'>
									{event.action}
								</td>
								<td className='py-2 px-4 border-b border-gray-700'>
									{event.kickedBy
										? `${event.kickedBy.name} (${event.kickedBy.id})`
										: 'N/A'}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TerritoryEvents;
